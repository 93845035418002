<template>
  <div class="event">
    <h1>Export sessions</h1>

    <div class="loading" v-if="loading">
      <span class="spinner-border" aria-hidden="true"></span>
    </div>
    <div class="loading" v-else-if="loadingError">
      <div class="row mt-4">
        <div class="col error">
          {{ loadingError }}
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <button class="btn btn-danger" @click="retryLoading">Try again</button>
        </div>
      </div>
    </div>
    <div class="statistics" v-else>
      <div class="filters">
        <div class="row">
          <div class="col">
            <Datepicker
              v-model="dateRange"
              range
              :preset-ranges="datePresetRanges"
              :preset-dates="datePresetRanges"
              position="left"
              multi-calendars
              multi-static
              :enable-time-picker="false"
              @update:model-value="onDateChanged"
            />
          </div>
        </div>
      </div>

      <div class="info-panel mt-3">
        <div v-if="isCounting">Counting estimated sessions..</div>
        <div v-else>Estimated session count: <strong>{{ sessionCount }}</strong></div>
      </div>

      <button class="btn btn-success mt-3" :disabled="exporting" @click="exportSessions">
        {{ exporting ? 'Exporting sessions..' : 'Export sessions' }}
      </button>

      <div class="error mt-3" v-if="errorMessage">
        Could not export zip:<br />{{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>

import DataChart from "@/components/chart/DataChart.vue";
import DataPoint from "@/components/chart/DataPoint.vue";

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  format,
  formatISO,
  parseISO,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  subDays,
  subMonths,
  subYears
} from 'date-fns';
import debounce from 'debounce';

export default {
  name: 'DashboardFavouriteView',
  components: {DataPoint, DataChart, Datepicker},
  data() {
    return {
      eventId: null,

      loading: true,
      data: null,

      dateRange: null,
      datePresetRanges: null,
      timeRange: null,

      loadingError: '',

      exporting: false,

      sessionCount: null,
      isCounting: false,

      sessionCountDebouncer: debounce(this.countSessionsInner, 200),

      errorMessage: null,
    };
  },
  computed: {
    dataPointTimeMinimumFormatted() {
      console.log('dataPointTimeMinimum', this.dataPointTimeMinimum);

      if (!this.dataPointTimeMinimum) {
        return '(never)';
      }

      try {
        return formatISO(this.dataPointTimeMinimum);
      } catch (e) {
        return '(never)';
      }
    },
    dataPointTimeMaximumFormatted() {
      console.log('dataPointTimeMaximum', this.dataPointTimeMaximum);

      if (!this.dataPointTimeMaximum) {
        return '(never)';
      }

      try {
        return formatISO(this.dataPointTimeMaximum);
      } catch (e) {
        return '(never)';
      }
    }
  },
  methods: {
    recalculateDateRange() {
      const datePresetRanges = [
        {
          label: 'Today',
          range: [new Date(), new Date()]
        },
        {
          label: 'This week',
          range: [startOfISOWeek(new Date()), endOfISOWeek(new Date())]
        },
        {
          label: 'This month',
          range: [startOfMonth(new Date()), endOfMonth(new Date())]
        },
        {
          label: 'Last 7 days',
          range: [subDays(new Date(), 7), new Date()],
        },
        {
          label: 'Last 30 days',
          range: [subDays(new Date(), 30), new Date()],
        },
        {
          label: 'Last 3 months',
          range: [subMonths(new Date(), 3), new Date()],
        },
        {
          label: 'Last 6 months',
          range: [subMonths(new Date(), 6), new Date()],
        },
        {
          label: 'Last 1 year',
          range: [subYears(new Date(), 1), new Date()],
        },
        {
          label: 'Last 2 years',
          range: [subYears(new Date(), 2), new Date()],
        },
        /*{
          label: 'All time',
          range: [subYears(new Date(), 100), new Date()],
        }*/
      ];

      let timeFrom = startOfDay(subDays(new Date(), 30));
      let timeTo = endOfDay(new Date());

      if (this.$route.query) {
        if (this.$route.query.fromTime) {
          timeFrom = parseISO(this.$route.query.fromTime);
        }

        if (this.$route.query.toTime) {
          timeTo = parseISO(this.$route.query.toTime);
        }
      }

      if (this.dataPointTimeMinimum) {
        //timeFrom = max([timeFrom, this.dataPointTimeMinimum]);
        //timeTo = max([timeTo, this.dataPointTimeMinimum]);

        for (const datePresetRange of datePresetRanges) {
          //datePresetRange.range[0] = max([datePresetRange.range[0], this.dataPointTimeMinimum]);
          //datePresetRange.range[1] = max([datePresetRange.range[1], this.dataPointTimeMinimum]);
        }
      }

      if (this.dataPointTimeMaximum) {
        //timeFrom = min([timeFrom, this.dataPointTimeMaximum]);
        //timeTo = min([timeTo, this.dataPointTimeMaximum]);

        for (const datePresetRange of datePresetRanges) {
          //datePresetRange.range[0] = min([datePresetRange.range[0], this.dataPointTimeMaximum]);
          //datePresetRange.range[1] = min([datePresetRange.range[1], this.dataPointTimeMaximum]);
        }
      }

      this.dateRange = [timeFrom, timeTo];
      this.datePresetRanges = datePresetRanges;

      this.timeRange = [formatISO(timeFrom), formatISO(timeTo)];
    },
    updateUrlQuery() {
      const query = {};

      query.fromTime = this.queryTimeRange[0];
      query.toTime = this.queryTimeRange[1];

      console.log('updating query to', query);

      this.$router.push({path: this.$route.fullPath, query: query});
    },
    onDateChanged() {
      this.timeRange = [
        formatISO(startOfDay(this.dateRange[0])),
        formatISO(endOfDay(this.dateRange[1]))
      ];

      if (this.dateRange && this.dateRange.length) {
        this.queryTimeRange = [
          format(parseISO(this.timeRange[0]), 'yyyy-MM-dd'),
          format(parseISO(this.timeRange[1]), 'yyyy-MM-dd'),
        ];
      } else {
        this.dateRange = [
          startOfDay(subDays(new Date(), 30)),
          endOfDay(new Date()),
        ];

        this.queryTimeRange = null;
      }

      this.countSessions();

      this.updateUrlQuery();
    },
    retryLoading() {
      this.getEventDataPoints();
    },
    getEventDataPoints() {
      this.loading = true;

      this.recalculateDateRange();

      this.loading = false;
    },
    exportSessions() {
      this.errorMessage = null;
      this.exporting = true;

      this.$apiClient.mixins.sessions.exportSessionsToZip(this.queryTimeRange[0], this.queryTimeRange[1]).then(
        async (response) => {
          if (response.data.type === 'application/json') {
            // NOT A ZIP FILE!
            const json = await response.data.text();
            const data = JSON.parse(json);

            console.log('response error', data);

            if (!data.success) {
              this.errorMessage = data.error;
            } else {
              this.errorMessage = 'No zip file returned';
            }

            this.exporting = false;
          } else {

            this.downloadFileFromResponse(response);

            this.exporting = false;
          }
        });
    },
    startCountingSessions() {
      this.isCounting = true;
      this.sessionCount = null;
      this.sessionCountDebouncer.clear();
    },
    countSessions(immediate = false) {
      this.startCountingSessions();

      if (immediate) {
        this.countSessionsInner();
      } else {
        console.log('Starting session count debounce');
        this.sessionCountDebouncer();
      }
    },
    countSessionsInner() {
      this.startCountingSessions();

      console.log('Counting sessions');

      this.$apiClient.mixins.sessions.countSessions(this.queryTimeRange[0], this.queryTimeRange[1]).then((count) => {
        this.sessionCount = count;
        this.isCounting = false;
      });
    },
    downloadFileFromResponse(response) {
      const blob = new Blob([response.data], {type: 'application/zip'});

      const contentDisposition = (response.headers && response.headers['content-disposition']
        ? response.headers['content-disposition']
        : '');

      const filenameMatch = contentDisposition?.match(/fileIdentifier="(.+?)"/);
      const filename = filenameMatch ? filenameMatch[1] : 'zip_export.zip';

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  mounted() {
    this.getEventDataPoints();
    this.onDateChanged();
  }
}
</script>

<style lang="scss">
.event {
  text-align: left;
}

.error {
  color: #aa0000;
  font-weight: bold;
}

.all-time-checkbox {
  line-height: 40px;
}
</style>
