import {addGetParamsToUrl, ReturnType} from "@seriousgamesinteractive/api-client-vue3";

export default {
  async exportSessionsToZip(startTime, endTime) {
    return await this.post('/session/export', { startTime, endTime }, {}, {
      responseType: 'blob',
      responseReturnType: ReturnType.RawResponse,
    });
  },
  async countSessions(startTime, endTime) {
    const response = await this.get('/session/count', { startTime, endTime });

    return (response && response.sessionCount) ? response.sessionCount : 0;
  }
};
