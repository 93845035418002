<template>
  <div class="data-chart" :class="computedClasses">
    <div class="bg-dark text-light header-info-bar" :class="showFilters ? 'header-info-bar-with-filters' : ''">
      <div class="d-flex align-items-center">
        <div class="favourite-toggle-container pe-2">
          <favourite-toggle :model-value="isFavourited" @update:modelValue="onFavouriteToggleChanged" />
        </div>
        <div :title="dataPointTitle">
          {{ dataPointTitle }}
        </div>
        <div class="flex-grow-1">
          <div class="current-filters text-end" v-if="this.currentFilterValueLabels.length">
            <span class="badge text-bg-dark" v-for="label in this.currentFilterValueLabels">
              {{ label }}
            </span>
          </div>
        </div>
        <div class="text-right">
          <button class="btn btn-sm btn-filters" :class="filterButtonClass" v-if="hasFilters" @click="showFilters = !showFilters" v-text="filterButtonLabel" />
        </div>
      </div>
      <accordion :open="showFilters">
        <form class="data-filters bg-light text-dark border-dark p-3" v-if="hasFilters">
          <div class="row text-center">
            <strong>Filters</strong>
          </div>
          <div class="data-filter row g-3" v-for="dataFilter of dataFilters">
            <div class="col-4">
              <label>{{ dataFilter.label }}</label>
            </div>
            <div class="col">
              <bootstrap-select
                :options="dataFilterOptions[dataFilter.name]"
                :model-value="dataFilterValues[dataFilter.name]"
                @update:modelValue="onDataFilterValuesChanged(dataFilter.name, $event)"
                placeholder-text="(all)"
              />
            </div>
          </div>
        </form>
      </accordion>
    </div>
    <div class="bg-light border border-dark">
      <div class="loading" v-if="loading"><span class="spinner-border" aria-hidden="true"></span></div>
      <div class="datapoint-statistic" v-else>
        <div v-if="chartGroupBy === 'date'">
          <data-chart
            :chart-data="chartData"
            :data-point="dataPoint"
            :chart-data-type="chartDataType"
            :chart-type="chartType"
            :chart-group-by="chartGroupBy"
            :chart-statistic="chartStatistic"
            :data-filters="dataFilters"
            :display-type="displayType"
            :group-labels="groupLabels"
            :label-format="labelFormat"
            :date-group-by="dateGroupBy"
            :precision="precision"
            :colour-palette="colourPalette"
            :colour-palette-type="colourPaletteType"
            :colour-palette-use-pattern="colourPaletteUsePattern"
          />
        </div>
        <div v-if="chartGroupBy === 'total'">
          <div v-for="(count, label) in chartData" :key="label" class="row result-bar-row">
            <div class="col-3">
              <div class="p-2">
                <label>{{ label }}</label>
              </div>
            </div>
            <div class="col-9">
              <div class="p-2">
                <result-bar :total="chartDataTotal" :value="count" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataChart from "@/components/chart/DataChart.vue";
import {formatLabel} from "@/utils/labelFormatter";
import ResultBar from "@/components/common/ResultBar.vue";
import {BootstrapSelect} from "@seriousgamesinteractive/components-vue3/bootstrap";
import {Accordion} from "@seriousgamesinteractive/components-vue3/common";
import FavouriteToggle from "@/components/common/FavouriteToggle.vue";
import {mapGetters} from "vuex";
import {ColourPalette, ColourPaletteType} from "@/components/chart/colourPalettes";

export default {
  name: 'data-point',
  components: {FavouriteToggle, ResultBar, BootstrapSelect, Accordion, DataChart},
  props: {
    dataPoint: {
      type: Object,
      required: true,
    },
    eventName: {
      type: String,
      required: true,
    },
    timeRange: {
      //type: Array,
      required: false,
    },
    showTitleEventName: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      chartDataType: null,
      chartType: null,
      chartGroupBy: null,
      chartStatistic: null,
      displayType: null,
      groupLabels: [],
      labelFormat: null,
      chartData: [],
      dateGroupBy: null,
      dataFilters: [],
      dataFilterValues: {},
      dataFilterOptions: {},

      currentFilterValues: {},
      currentFilterValueLabels: [],

      showFilters: false,

      colourPalette: ColourPalette.DeepOceanToSunset,
      colourPaletteType: ColourPaletteType.Varied,
      colourPaletteUsePattern: true,
    };
  },
  computed: {
    ...mapGetters(['favourites']),
    isFavourited() {
      return this.favourites.filter((favourite) => {
        return favourite.eventName === this.eventName && favourite.dataPointName === this.dataPoint.data_point_name;
      }).length > 0;
    },
    precision() {
      console.log('dataPoint', this.dataPoint);
      return 0;
    },
    dataPointTitle() {
      return [
        this.showTitleEventName ? this.eventName : null,
        this.dataPoint.label
      ].filter(v => !!v).join(' / ');
    },
    chartDataTotal() {
      let total = 0;

      let keys = Object.keys(this.chartData);

      for (const key of keys) {
        total += this.chartData[key];
      }

      return total;
    },
    chartDataJson() {
      return JSON.stringify(this.chartData, null, '  ');
    },
    computedClasses() {
      return [
        this.chartDataType ? `data-chart-data-type-${this.chartDataType}` : null,
        this.chartType ? `data-chart-type-${this.chartType}` : null,
      ]
    },
    dataPointName() {
      return this.dataPoint.data_point_name;
    },
    hasFilters() {
      return this.dataFilters.length > 0;

      //return this.dataPoint.type !== 'filter' && this.dataFilters.length > 0;
    },
    filterButtonLabel() {
      if (this.currentFilterValueLabels.length) {
        return `Filters (${this.currentFilterValueLabels.length})`
      }

      return 'Filters';
    },
    filterButtonClass() {
      if (this.currentFilterValueLabels.length) {
        //return `btn-success`
      }

      return 'btn-light';
    }
  },
  watch: {
    timeRange() {
      this.loadChartData();
    },
  },
  mounted() {
    this.chartDataType = this.dataPoint.type;
    this.chartGroupBy = this.dataPoint.group_by;
    this.chartStatistic = this.dataPoint.statistic;
    this.chartType = this.dataPoint.chart_type;

    this.loadChartData();
  },
  methods: {
    onFavouriteToggleChanged(isFavourited) {
      this.$store.commit(isFavourited ? 'addFavourite' : 'removeFavourite', {
        eventName: this.eventName,
        dataPoint: this.dataPoint
      });
    },
    formatFilterLabel(label) {
      return formatLabel('format({value}', label);
    },
    loadChartData() {
      let options = {
        filters: this.currentFilterValues,
      };

      if (this.timeRange === 'allTime') {
        options.allTime = true;
      } else {
        if (this.timeRange && this.timeRange[0]) {
          options.timeFrom = this.timeRange[0];
        }

        if (this.timeRange && this.timeRange[1]) {
          options.timeTo = this.timeRange[1];
        }
      }

      this.$apiClient.mixins.events.getEventDataPointData(this.eventName, this.dataPointName, options).then((data) => {
        console.log('DATA1', data);

        const {
          dataPoints,
          dataPointType,
          dataFilters,
          groupLabels,
          dateGroupBy,
        } = data;

        this.chartData = dataPoints;
        this.displayType = dataPointType;
        this.groupLabels = groupLabels;
        this.dateGroupBy = dateGroupBy;

        if (dataFilters) {
          dataFilters.forEach((dataFilter) => {
            const filterFormat = dataFilter.format || null;

            dataFilter.values.forEach((value) => {
              value.label = formatLabel(filterFormat, value.label);
            });
          });
        }

        // Don't display self in the filter list
        const dataFiltersFiltered = dataFilters.filter((dataFilter) => {
          return this.dataPointName !== dataFilter.name;
        });

        this.labelFormat = this.dataPoint.format;

        if (!this.dataFilterValues && this.dataFilterOptions) {
          // First time
          const dataFilterValues = {};
          const dataFilterOptions = {};

          for (const dataFilter of dataFiltersFiltered) {
            const dataFilterOptionsSingle = [];

            for (let dataFilterValue of dataFilter.values) {
              dataFilterOptionsSingle.push({
                label: dataFilterValue.label,
                value: dataFilterValue.value,
              })
            }

            dataFilterValues[dataFilter.name] = '';
            dataFilterOptions[dataFilter.name] = dataFilterOptionsSingle;
          }

          this.dataFilterValues = dataFilterValues;
          this.dataFilterOptions = dataFilterOptions;
        } else {
          for (const dataFilter of dataFiltersFiltered) {
            const dataFilterOptionsSingle = [];

            for (let dataFilterValue of dataFilter.values) {
              dataFilterOptionsSingle.push({
                label: dataFilterValue.label,
                value: dataFilterValue.value,
              })
            }

            //this.dataFilterValues[dataFilter.name] = '';
            this.dataFilterOptions[dataFilter.name] = dataFilterOptionsSingle;
          }
        }

        //formatLabel(this.labelFormat, key)

        this.dataFilters = dataFiltersFiltered;

        //this.showFilters = Object.keys(dataFiltersFiltered).length > 0;

        console.log('dataFilterValues', {
          dataFilterValues: this.dataFilterValues,
          dataFilterOptions: this.dataFilterOptions,
          dataFilters: this.dataFilters,
        });

        this.$nextTick(() => {
          this.loading = false;
        });
      })
    },
    onDataFilterValuesChanged(dataFilterName, dataFilterValue) {
      this.dataFilterValues[dataFilterName] = dataFilterValue;

      const filterValues = {};
      const filterValueLabels = [];

      for (const dataFilter of this.dataFilters) {
        const dataFilterValue = this.dataFilterValues[dataFilter.name];
        const dataFilterLabelFormat = dataFilter.format;

        if (dataFilterValue === null || dataFilterValue === '' || dataFilterValue === undefined) {
          continue;
        }

        filterValues[dataFilter.name] = dataFilterValue;
        filterValueLabels.push(`${dataFilter.label}: ${formatLabel(dataFilterLabelFormat, dataFilterValue)}`)
      }

      this.currentFilterValues = filterValues;
      this.currentFilterValueLabels = filterValueLabels;

      this.loadChartData();
    }
  }
}
</script>

<style lang="scss" scoped>
.data-chart {
  padding: 15px;
  margin-bottom: 30px;
  border-radius: 15px;

  &.data-chart-data-type-dynamic {
    .header-info-bar {
      background-color: #30362F !important;
    }

    .border {
      border-color: #30362F !important;
    }
  }

  &.data-chart-data-type-dataPoint {
    .header-info-bar {
      background-color: #625834 !important;
    }

    .border {
      border-color: #625834 !important;
    }
  }

  &.data-chart-data-type-filter {
    .header-info-bar {
      background-color: #571F4E !important;
    }

    .border {
      border-color: #571F4E !important;
    }
  }
}

.header-info-bar {
  padding: 15px;
  position: relative;

  .d-flex {
    > div {
      line-height: 32px;
    }
  }
}

.canvas-container-wrapper {
  border-radius: 0 0 5px 5px;
}

.loading {
  padding: 15px;
}

.data-filters {
  margin-left: auto;
  max-width: 500px;
  margin-top: 10px;

  label {
    padding: 10px;
  }

  select {
    height: 100%;
  }

  strong {
    font-size: 14pt;
    margin-bottom: 15px;
  }
}

.current-filters {
  padding-right: 15px;

  .badge {
    margin-left: 15px;

    &:first-child {
      margin-left: 0 !important;
    }
  }
}

.result-bar-row {
  margin-bottom: 10px;
}

.favourite-toggle-container {
  padding: 0;
  margin: 0;
  line-height: auto;
}
</style>
