import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { createI18n } from 'vue-i18n';
import localMessages from './lib/i18n/index.js';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import ApiClient from "@seriousgamesinteractive/api-client-vue3";
import ApiClientMixins from "@/plugins/ApiClientMixins";

const app = createApp(App);

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: localMessages,
});

import { SnackbarService, Vue3Snackbar } from "vue3-snackbar"
import "vue3-snackbar/styles";

//Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

app
  .use(store)
  .use(router)
  .use(i18n)
  .use(ApiClient, {
    mixins: ApiClientMixins,
    baseUrl: process.env.VUE_APP_BASE_DASHBOARD_API_URL || '/api/dashboard/',
    useDebugConsole: true,
  })
  .use(SnackbarService);

app.component('vue3-snackbar', Vue3Snackbar);

app.mount('#app')
